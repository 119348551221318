class QuantityInput {
    constructor(selector) {
        this.selector = selector;


        this.sync_selector = this.selector.getAttribute('data-synchronize');
        this.sync = (typeof this.sync_selector !== "undefined" && this.sync_selector !== null && this.sync_selector !== '');

        this.input = this.selector.querySelector('[data-element="input"]');
        this.increase = this.selector.querySelector('[data-element="increase"]');
        this.decrease = this.selector.querySelector('[data-element="decrease"]');

        this.meters = this.selector.querySelector('[data-element="meters-in-box"]');

        this.increaseQty = this.increaseQty.bind(this);
        this.decreaseQty = this.decreaseQty.bind(this);
        this.inputChange = this.inputChange.bind(this);

        if (this.input !== null && this.increase !== null && this.decrease !== null) {
            this.increase.addEventListener('click', this.increaseQty);
            this.decrease.addEventListener('click', this.decreaseQty);

        }
        if (this.input !== null && this.meters !== null) {
            this.input.addEventListener('change', this.inputChange);
            this.updateMeters();
        }

        this.selector.nt_qty = this;
    }

    increaseQty(event) {
        event.preventDefault();
        if (!this.sync) {
            this.updateInput('increase');
        } else {
            let qts = document.querySelectorAll(this.sync_selector);
            qts.forEach(qt => {
                qt.nt_qty.updateInput('increase');
            })
        }
    }

    decreaseQty(event) {
        event.preventDefault();
        if (!this.sync) {
            this.updateInput('decrease');
        } else {
            let qts = document.querySelectorAll(this.sync_selector);
            qts.forEach(qt => {
                qt.nt_qty.updateInput('decrease');
            })
        }
    }

    inputChange(event) {
        if (!this.sync) {
            this.updateMeters();
        } else {
            let qts = document.querySelectorAll(this.sync_selector);
            qts.forEach(qt => {
                qt.nt_qty.input.value = this.input.value;
                qt.nt_qty.updateMeters();
            });
        }
    }

    updateInput(method) {
        let qty = this.input.value;
        let qtyMin = parseInt(this.input.getAttribute('min'));
        let qtyMax = parseInt(this.input.getAttribute('max'));
        switch (method) {
            case 'increase': {
                qty++;
                if (qtyMax !== 0) {
                    if (qty > qtyMax) qty = qtyMax;
                }
                break;
            }
            case 'decrease': {
                qty--;
                if (qty < qtyMin) qty = qtyMin;
                break;
            }
        }
        let update_cart_btn = document.querySelector('button[name="update_cart"]');
        if (update_cart_btn !== null && update_cart_btn.disabled) {
            update_cart_btn.disabled = false;
        }
        this.input.value = qty;
        if (this.meters !== null) {
            this.updateMeters();
        }
    }

    updateMeters() {
        let meters_inbox = parseFloat(this.meters.getAttribute('data-amount'));
        let qty = parseFloat(this.input.value);
        let meters = qty * meters_inbox;

        this.meters.innerHTML = meters.toFixed(2) + 'm<sup>2</sup>';
    }

}

export default QuantityInput;