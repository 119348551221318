class PaginationInput {
    constructor(selector) {
        this.selector = selector;
        this.link = this.selector.getAttribute('data-link');

        this._change = this._change.bind(this);
        this._keyUp = this._keyUp.bind(this);

        this.selector.addEventListener('change', this._change);
        this.selector.addEventListener('keyup', this._keyUp);

    }

    _change(event) {
        this._changePage(event.target.value);
    }

    _keyUp(event) {
        if (event.keyCode === 13) {
            this._changePage(event.target.value);
        }
    }

    _changePage(new_page) {
        let link = this.link.replace('%#%', new_page);
        window.location.href = link;
    }

}

export default PaginationInput;