import $ from 'jquery';

(function ($) {
    $.fn.attributeSlider = function () {
        var $el = null;
        var $slider = null;
        var $min = null;
        var $max = null;
        var $final = null;

        var max = 0;
        var min = 0;
        var current_min = 0;
        var current_max = 0;
        var option_values = [];

        var init = function () {
            $slider = $el.find('[data-element="slider"]');
            $min = $el.find('[data-element="attribute_min"]');
            $max = $el.find('[data-element="attribute_max"]');
            $final = $el.find('[data-element="final_input"]');

            $slider.show();

            min = $min.data('min');
            max = $max.data('max');
            current_min = $min.val();
            current_max = $max.val();

            var tmp_options = $el.data('options');
            option_values = tmp_options.split(',');

            $slider.slider({
                range: true,
                animate: true,
                min: min,
                max: max,
                values: [current_min, current_max],
                step: 1,
                create: function () {
                    $min.val(current_min);
                    $max.val(current_max);
                },
                slide: function (event, ui) {
                    $min.val(ui.values[0]);
                    $max.val(ui.values[1]);
                    current_min = ui.values[0];
                    current_max = ui.values[1];


                    var cmin = parseFloat(current_min);
                    var cmax = parseFloat(current_max);


                    var result = [];

                    $.each(option_values, function (key, val) {
                        var tmp = parseFloat(val);
                        if (tmp >= cmin && tmp <= cmax) result.push(tmp);
                    });


                    $final.val(result.join(','));
                },
                change: function (event, ui) {
                    $min.val(ui.values[0]);
                    $max.val(ui.values[1]);
                    current_min = ui.values[0];
                    current_max = ui.values[1];


                    var cmin = parseFloat(current_min);
                    var cmax = parseFloat(current_max);


                    var result = [];

                    $.each(option_values, function (key, val) {
                        var tmp = parseFloat(val);
                        if (tmp >= cmin && tmp <= cmax) result.push(tmp);
                    });


                    $final.val(result.join(','));

                }
            });

            $min.on('change', onMinChange);
            $max.on('change', onMaxChange);

        };

        var onMinChange = function (e) {
            current_min = $(this).val();
            if (current_min >= min) {
                if (current_min > max) {
                    $slider.slider({
                        values: [max, max]
                    });
                    $(this).val(max);
                    $max.val(max);
                } else if (current_min > current_max) {
                    $slider.slider({
                        values: [current_min, current_min]
                    });
                    $max.val(current_min);
                } else {
                    $slider.slider({
                        values: [current_min, current_max]
                    })
                }
            } else {
                $slider.slider({
                    values: [min, current_max]
                })
                $(this).val(min);
            }
        }
        var onMaxChange = function (e) {
            current_max = $(this).val();
            if (current_max <= max) {
                if (current_max < min) {
                    $slider.slider({
                        values: [min, min]
                    });
                    $(this).val(min);
                    $min.val(min);
                } else if (current_max < current_min) {
                    $slider.slider({
                        values: [current_max, current_max]
                    });
                    $min.val(current_max);
                } else {
                    $slider.slider({
                        values: [current_min, current_max]
                    })
                }
            } else {
                $slider.slider({
                    values: [current_min, max]
                })
                $(this).val(max);
            }
        }

        if (this.length > 0) {
            if (this.length > 1) {
                this.each(function () {
                    $(this).attributeSlider();
                })
            } else {
                $el = this;
                init();
            }
        }
    }
})($);

$(document).ready(function ($) {
    $('.js-attribute-slider').attributeSlider();
});